.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.pseudoBackBtn {
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.marginLeft {
  margin-left: 19px;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subtitle {
  font-family: LADAPragmatica, serif;
  text-transform: uppercase;
  font-size: 16px;
  color: var(--color-gray)
}
