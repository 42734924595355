.wrapper {
  margin-right: 8px;
  text-align: right;
  font-family: LADAPragmatica, serif;
  color: var(--color-mainDark)
}

.position {
  color: #8B9095
}

.fullName {

}

.shortName {
  display: none;
}

@media screen and (max-width: 1440px) {
  .welcome {
    display: none;
  }

  .fullName {
    display: none;
  }

  .shortName {
    display: block;
  }
}

