.count {
  font-family: var(--LadaPragmatica);
  font-size: 36px;
  color: var(--color-mainDark);
  margin-bottom: 10px;
}

.name {
  font-family: var(--LadaPragmatica);
  font-size: 14px;
  color: var(--color-gray);
}
