.container {
  max-width: 900px;
  font-family: LADAPragmatica, serif;
}
.selectList {
  display: flex;
  max-width: 900px;
  gap: 20px;
}
.checkboxItem {
  display: flex;
  align-items: flex-end;
  width: 50%;
  color: #66727D;
  margin-bottom: 12px;
}

.loading {
  padding: 60px 80px;
  display: flex;
  justify-content: center;
  color: #66727D;
  font-size: 16px;
  font-family: 'LADAPragmatica', serif;
}
