.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 30px;
  background: #fff;
  position: sticky;
  height: content-box;
  align-self: flex-start;
  transition: margin-top .2s ease-in-out;
}
