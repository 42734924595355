.container {
  position: fixed;
  bottom: 40px;
  left: 40px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
  z-index: 99;
}

.element {
  display: flex;
  align-items: center;
  padding: 14px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 12px rgba(77, 89, 100, 0.12);
  border-radius: 4px;
  animation: inAnimation .5s cubic-bezier(0.02, 1, 0.55, 1.03);
}

.borderApply {
  border-left: 4px solid #55A549;
}

.borderWarning {
  border-left: 4px solid #E37639;
}

.borderError {
  border-left: 4px solid #DA4645;
}

.element > p {
  margin-left: 10px;
  font-family: LADAPragmaticaBold, serif;
  color: var(--color-mainDark);
  font-weight: 700;
}

.close {
  margin-left: auto;
  min-width: 20px;
  height: 20px;
  margin-bottom: auto;
}

.ico {
  min-width: 18px;
  margin-bottom: auto;
}

@keyframes inAnimation {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
