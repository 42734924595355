.container {
  display: flex;
  flex-direction: column;
  min-width: 366px;
}

.subContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.wrapper {
  padding: 20px 20px 20px 17px;
}

.border {
  border-bottom: 1px solid #E5E9ED;
}

.marginLeft {
  margin-left: auto;
}

.templateList {
  min-height: 510px;
  overflow: hidden;
}

.templateList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
}

/* ползунок scrollbar`а */
.templateList::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}

.templateElement {
  max-width: 360px;
  display: flex;
  justify-content: space-between;
  font-family: var(--LadaPragmatica);
  padding: 15px 20px;
  border-bottom: 1px solid #E5E9ED;
  border-left: 3px solid #fff;
}

.templateElement:hover {
  cursor: pointer;
  background: rgba(166, 173, 181, 0.23);
  border-left: 3px solid rgba(166, 173, 181, 0.04);
}

.templateElementSelected {
  border-left: 3px solid var(--color-orange);
}

.templateElementSelected:hover {
  border-left: 3px solid var(--color-orange);
}

.name {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.preloader {
  margin-left: auto;
  margin-right: 10px;
}

.date {
  color: var(--color-lightGray);
  line-height: 20px;
}

.paginationWrapper {
  padding: 15px;
  margin-top: auto;
  background: #fff;
}

.pagination{
  width: auto;
  font-family: LADAPragmatica, serif;
}

