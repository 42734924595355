.contentWrapper {
  display: flex;
  gap: 24px;
}

.dataContainer {
  width: 60%;
  padding: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  gap: 30px 45px;
}

.groupContainer {
  width: 40%;
  background-color: #fff;
  padding: 24px 24px 24px 32px;
}

.col {
  display: flex;
  flex-direction: column;
}
.dataCell {
  font-family: var(--LadaPragmatica) ,serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.dataCellAddress {
  grid-column-start: 2;
  grid-column-end: 4;
}

.title {
  color: var(--color-gray);
}

.text {
  color: var(--color-mainDark);
}

.buttons {
  padding-top: 24px;
  border-top: 1px solid var(--color-border);
  display: flex;
  gap: 16px;
}



/* commonContainer */
.commonContainer {
  margin-top: 24px;
  padding: 24px;
  background-color: #fff;
  width: 100%;
}

.commonContainerButtons {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}