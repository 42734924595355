.file {
  display: flex;
  align-items: center;
}

.name, .size {
  margin-left: 8px;
  font-family: var(--LadaPragmatica);
  font-size: 15px;
}

.name {
  max-width: 200px;
  text-overflow: ellipsis;
  color: var(--color-darkGray);
  white-space: nowrap;
  overflow: hidden;
}

.name a {
  color: var(--color-darkGray);
  text-decoration: none;
}

.name a:hover {
  color: var(--color-orange);
}

.size {
  color: var(--color-lightGray);
}

.delete {
  margin-left: 8px;
  cursor: pointer;
}

.delete path {
  transition: all .3s;
}

.delete:hover path {
  stroke: var(--color-darkGray) !important;
}
