.change-pass {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--LadaPragmatica);
  color: var(--color-mainDark);
  cursor: pointer;
}

.change-pass > label {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 446px;
}
