.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropContainer {
  min-height: 100px;
  display: flex;
  flex-direction: column;
}

.dropContainer > p {
  font-family: LADAPragmatica, serif;
}

.emptyQuestionList {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.04);
}
