.footer {
  display: flex;
  color: #66727D;
  height: 35px;
  font-family: var(--LadaPragmatica);
}

.iconsItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity .2s;
}

.iconsItem img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.iconsItem:hover {
  opacity: 0.75;
}

.iconsItem:first-child {
  margin-right: 25px;
  align-items: center;
}

.checkboxList {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 32px 0;
  overflow-y: scroll;
  height: 252px;

  /*max-width: 690px;*/
  width: 806px;
}

.checkboxList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
}

.checkboxList::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}

.empty {
  color: #66727d;
  font-family: var(--LadaPragmatica);
}

.controlButtons {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
