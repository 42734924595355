.container {
  display: flex;
  flex-direction: column;
}

.modulesWrapper {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.modulesRow {
  display: flex;
  justify-content: stretch;
  gap: 24px;
}

@media screen and (max-width: 1400px) {
  .modulesRow {
    flex-direction: column;
  }
}
