:root {
  /*COLORS CONSTANTS*/
  --color-mainBackground: #F4F4F4;
  --color-mainDark: #4D5964;
  --color-orange: #F5671E;
  --color-orangeShade: #E37639;
  --color-lightGray: #A6ADB5;
  --color-darkGray: #66727D;
  --color-gray: #848E98;
  --color-border: #E5E9ED;
  --color-white: #FFFFFF;
  --color-blueShade-links: #3987E3;
  --color-error-red: #ff5656;
  --color-green: #3DB05D;
  --color-dark: #66727D;
  --color-dark-blue: #4C5865;

  --color-green-table: #EFF9F3;

  --color-bar-background: #EEEEEE;
  --color-bar-filled: #E7A94E;

  /*SIZE CONSTANTS*/
  --header-h3: 32px;
  --header-h4: 28px;
  --header-height: 80px;

  /* FONTS CONSTANTS */
  --LadaPragmatica: 'LADAPragmatica';
  --LadaPragmaticaB: 'LADAPragmaticaBold';
  --Pragmatica: 'Pragmatica'
}
