.container {
  background-color: var(--color-white);
  padding: 8px;
  font-family: var(--LadaPragmatica);
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  outline: none;
}

.value {
  color: var(--color-dark);
  font-family: var(--LadaPragmaticaB);
}
