.wrapper {
  padding: 20px;
  display: flex;
  width: 100%;
  background: #fff;
  align-self: flex-start;
  gap: 20px;
  align-items: flex-end;
}

.input {
  font-family: Pragmatica, serif;
  font-size: 32px;
  color: #4D5964;
}
