.wrapper {
  width: 500px;
}

.uncontrolledField {
  height: 56px;
  padding-top: 11px;
  border-bottom: var(--color-border) 1px solid;
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica), serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
