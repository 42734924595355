.container {
  position: relative;
  padding-top: 10px;
}

.label {
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica);
  font-size: 16px;
  pointer-events: none;
  position: absolute;
  top: 25px;
  left: 30px;
  transition: .3s;
}

.filled {
  transform: translate(-30px, -20px);
  font-size: 10px;
}
