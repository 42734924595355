.button {
  position: relative;
  background: var(--color-lightGray);
  padding: 9px 9px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  color: var(--color-white);
  border: none;
  border-radius: 50%;
}

.preloader {
  border-radius: 50%;
  position: absolute;
  width: 5px;
  height: 5px;
  top: -2px;
  background: #4C5865;
  animation: 1s infinite preloaderAnimation linear;
}

@keyframes preloaderAnimation {
  from {
    transform-origin: -9px 30px;
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
