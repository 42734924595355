.container {
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.files {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 200px;
}

.filesOverlay {
  position: relative;
}

.filesOverlay::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  content: '';
  pointer-events: none;
}

.createFileButton {
  background-color: var(--color-white);
  color: var(--color-gray);
  border: 1px solid var(--color-border);
  position: absolute;
  bottom: 20px;
  left: 24px;
}

.statisticContainer {
  display: flex;
  flex-wrap: wrap;
}

.statisticContainer > div {
  width: 50%;
  margin-bottom: 27px;
}

/* На большом экране надо будет править*/
/*@media screen and (max-width: 1440px){*/
/*  .container {*/
/*    justify-content: center;*/
/*  }*/
/*}*/

