.box {
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-lightGray);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  display: none;
}

.checked {
  background: #E37639;
  border: 1px solid #E37639;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: LADAPragmatica, serif;
}
