:root{
  --table-cell-padding: 15px 24px;
}

.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.submenu {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  margin-bottom: 12px;
  padding: 20px 24px;
}

.submenu a {
  font-family: var(--LadaPragmaticaB);
  color: var(--color-mainDark);
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
}

.submenu a:not(:last-child) {
  margin-right: 20px;
}

.bodyContainer {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 24px;
}

.wrapper {
  position: relative;
  /*height: calc(100vh - 380px);*/
  height: calc(100vh - 354px);
  /*height: calc(100vh - 273px);*/
  flex-grow: 2;
  overflow: scroll;
  width: 100%;
  background: #fff;
}

.preloaderWrapper {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableTop {
  min-height: 35px;
  align-items: flex-start;
  display: flex;
}

.table {
  font-family: LADAPragmatica, serif;
  width: 100%;
}

.table>*>tr {
  border-bottom: 1px solid #E5E9ED;
}

.table>thead>tr>th {
  font-size: 13px;
  text-transform: uppercase;
  text-align: left;
  padding: var(--table-cell-padding);
  color: var(--color-lightGray);
}

.table>tbody>tr>td {
  font-size: 15px;
  padding: var(--table-cell-padding);
  color: var(--color-mainDark);
}

.footer{
  height: 50px;
  background: #4D5964;
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 4px solid var(--color-gray);
}

.arrowDownActive {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 4px solid var(--color-blueShade-links);
}

.arrowUpActive {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-bottom: 4px solid var(--color-blueShade-links);
}

/**
    Scroll bar
 */
/* полоса прокрутки (scrollbar) */
.wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
}

/* ползунок scrollbar`а */
.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}
/*!* Для FireFox *!*/
/*.wrapper {*/
/*  scrollbar-color: var(--color-lightGray) var(--color-white);*/
/*  scrollbar-width: thin;*/
/*}*/

.disabled {
  opacity: .3;
}
