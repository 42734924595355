.container {
  display: flex;
  flex-direction: column;
  min-width: 100px;
}

.wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.imageViewModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
