.picker {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete {
  background-color: var(--color-white);
  border: 1px solid var(--color-error-red);
  color: var(--color-error-red);
}
