.header {
  text-transform: uppercase;
  color: var(--color-dark-blue);
  font-family: var(--Pragmatica), serif;
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 51px;
}

.list {
  display: flex;
  list-style: none;
  gap: 24px;
  margin-top: 24px;
}

.item {
  background-color: #fff;
  padding: 24px;
  display: flex;
  height: 212px;
  flex-direction: column;
  justify-content: space-between;
  width: 326px;
  flex: 0 0 326px;
}

.item svg {
  height: 56px;
  width: 56px;
}

.text {
  color: var(--color-gray);
  font-family: var(--LadaPragmatica), serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.link {
  color: var(--color-blueShade-links);
  font-size: 15px;
  margin-bottom: 2px;
  cursor: pointer;
}

.text_link {
  color: var(--color-blueShade-links);
  text-decoration: none;
}

@media (max-width: 768px) {
  .header {
    font-size: 26px;
  }
  .list {
    flex-direction: column;
  }
  .item {
    height: auto;
    padding: 12px;
  }
  .item svg {
    margin-bottom: 12px;
  }
}
