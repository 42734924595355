.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px;
  height: var(--header-height);
  background: #FFF;
}

.headerRight {
  display: flex;
  align-items: center;
}

.test {
  font-size: var(--header-h3)
}

.screenBig {
  display: flex;
}

.screenSm {
  display: none;
}

@media screen and (max-width: 1024px){
  .screenBig {
    display: none;
  }
  .screenSm {
    display: block;
  }
  .header {
    padding: 32px 16px;
  }
  .logoLink {
    width: 120px;
  }
  .logoLink > img {
    width: inherit;
  }
}
