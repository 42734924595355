.wrapper {
  position: relative;
}

.wrapper > p {
  text-transform: uppercase;
  font-size: 12px;
}

.hiddenBtn {
  background: none;
  border: none;
  color: var(--color-gray);
}

.variantList {
  padding: 5px;
  text-align: right;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
}

.variantList > ul > li {
  cursor: pointer;
  margin-bottom: 5px;
}

.variantList > ul > li:last-child {
  margin-bottom: 0;
}

.variantList > ul > li:hover {
  text-decoration: underline;
}

.isShow {
  display: block;
}
