.container {
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow: scroll;
}

.textarea {
  width: 100%;
  min-height: 100px;
  resize: none;
  border: none;
  font-family: LADAPragmatica, serif;
  font-size: 16px;
  color: var(--color-mainDark);
}
