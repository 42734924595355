.container {
  display: flex;
  flex-direction: column;
  width: 100%;

}
.optionContainer {
  cursor: pointer;
  padding: 15px 0;
  display: flex;
  font-family: LADAPragmatica, serif;
  color: #4D5964;
  border-bottom: 1px solid #fff;
}
.optionContainer:hover {
  border-bottom: 1px solid black;
}
.optionText {
  width: 346px;
}

.icoWrapper {
  cursor: pointer;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}
.icoWrapper:hover {
  border: 1px solid var(--color-orangeShade);
  border-radius: 3px;
}
