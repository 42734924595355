.button {
  background: none;
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  color: var(--color-mainDark);
  border: none;
  border-radius: 8px;
}

.disabled {
  opacity: .3;
}

.leftMargin{
  margin-left: 5px;
}
.rightMargin{
  margin-right: 5px;
}
