.container {
  flex-grow: 2;
  background: #fff;
  padding-top: 12px;
  overflow: auto;
  max-height: 520px;
}

.table {
  width: 100%;
  color: var(--color-mainDark)
}

.tableRow {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}

.tableHeader {
  font-family: LADAPragmatica, serif;
  color: #848E98;
}

.background {
  background: #F4F4F4;
}

.tableCell {
  font-family: LADAPragmatica, serif;
  padding: 15px 27px;
}

.tableCellHeader {
  font-family: LADAPragmaticaBold, serif;
  font-weight: 700;
}

.tableCellBorder {
  border: 1px solid silver;
}

.noTopBorder {
  border-top: none;
}
