.wrapper {
  width: 100%;
  position: relative;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
}
.input {
  outline: none;
  border: none;
  width: 100%;
  /*border-bottom: 1px solid var(--color-border);*/
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--LadaPragmatica);
  color: var(--color-mainDark);
  background: transparent;
  text-overflow: ellipsis;
}
.input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica);
}

.input:focus {
  outline: none;
}
.input_empty {
  padding: 18px 0 6px;
}
.input_filled {
  padding: 18px 0 6px;
}

.control {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 16px;
  right: 0;
}

.controlElement {
  cursor: pointer;
  display: none;
}

.showControl{
  display: block;
}

.isHide {
}

.error {
  margin-top: 3px;
  color: var(--color-error-red);
  bottom: 6px;
  font-size: 12px;
  font-family: var(--LadaPragmatica);
}

.select__subheader {
  position: absolute;
  top: 2px;
  left: 0;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}

.suggest {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
  list-style: none;
  font-family: LADAPragmatica, serif;
  border: 1px solid var(--color-border);
  border-top: 0;
  color: var(--color-gray);
}

.suggest > li {
  padding: 5px;
  cursor: pointer;
}

.suggest > li:hover {
  background-color: var(--color-lightGray);
  color: var(--color-white);
}
