.wrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  opacity: 0;
  cursor: pointer;
  margin-left: 10px;
  transition: opacity .15s ease;
}

.wrapper:hover > .iconWrapper {
  opacity: 1;
}

.showEdit {
  opacity: 1;
}

.noRelative {
  position: inherit;
}

.btnBlock {
  margin-left: 5px;
  display: flex;
}
