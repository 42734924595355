.wrapper{
  min-height: 661px;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  gap: 24px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  font-family: Pragmatica, serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 200;
  font-size: 26px;
  color: var(--color-mainDark);
  line-height: 33px;
  text-align: center;
  padding: 0 173px;
  align-self: stretch;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 1200px) {
  .content {
    font-size: 20px;
    padding: 0 103px;
  }
}

.editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
