.text {
  font-family: var(--LadaPragmatica), serif;
  color: var(--color-mainDark);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.input {
  margin-top: 34px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.status {
  font-family: var(--LadaPragmatica), serif;
  color: var(--color-gray);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  
  margin-top: 24px;
  padding: 16px;
  background-color: var(--color-mainBackground);
}

.statusTitle {
  color: var(--color-mainDark);
  font-size: 15px;
  margin-bottom: 13px;
}

.statusData {
  text-transform: uppercase;
  margin-right: 7px;
}