.container {
  height: 50px;
  width: 100%;
  background: #fff;
  margin-bottom: 4px;
  display: flex;
}

.fieldBtn {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--table-cell-padding);
  border-right: 1px solid #F2F4F5;
}

.marginLeft {
  margin-left: 16px;
}

.label {
  margin-bottom: -3px;
  font-family: LADAPragmatica, serif;
  color: var(--color-mainDark)
}

.selectedCount{
  color: #000;
  margin-left: 12px;
  margin-bottom: -3px;
  font-family: LADAPragmatica, serif;
}
