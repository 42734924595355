.title {
  font-size: var(--header-h3);
  color: var(--color-mainDark);
  text-transform: uppercase;
  font-family: var(--Pragmatica);
  font-weight: 200;
}

.button {
  cursor: pointer;
  font-family: var(--LadaPragmaticaB);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.selectableTableCell {
  padding: var(--table-cell-padding);
  padding-left: 24px !important;
  padding-right: 5px !important;
}

.link {
  color: var(--color-orange);
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.link > span {
  color: var(--color-lightGray);
}

.linkUpper {
  text-transform: uppercase;
  color: var(--color-blueShade-links);
  font-family: LADAPragmaticaBold, serif;
  font-weight: 700;
  font-size: 11px;
}

.fieldError {
  margin-top: 3px;
  color: var(--color-error-red);
  bottom: 6px;
  font-size: 12px;
  font-family: var(--LadaPragmatica);
}
