.container {
  margin-bottom: 20px;
  position: relative;
  padding-left: 50px;
  padding-right: 25px;
}

.icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  max-height: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
}

.file {
  flex: 1 0 auto;
  font-family: var(--LadaPragmatica);
}

.name {
  color: var(--color-blueShade-links);
  font-size: 15px;
  margin-bottom: 6px;
  cursor: pointer;
}

.preloadText {
  opacity: .5;
  color: #4D5964;
  margin-bottom: 5px;
}

.desc {
  color: var(--color-gray);
  font-size: 13px;
  text-transform: uppercase;
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
