.text {
  font-family: var(--LadaPragmatica), serif;
  color: var(--color-mainDark);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.input {
  margin-top: 34px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.container {
  max-width: 500px;
}

.question > * {
  font-family: LADAPragmatica, serif;
  color: var(--color-gray);
}

.bottomFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 15px;
}

.bottomFormButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
