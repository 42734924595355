.actions {
  display: flex;
}

.iconWrapper {
  opacity: 0;
  cursor: pointer;
  margin-left: 10px;
  transition: opacity .15s ease;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.wrapper:hover > .iconWrapper {
  opacity: 1;
}

.selectWrapper {
  min-width: 220px;
}
