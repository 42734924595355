.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
}

.paginationPages {
  display: flex;
}

.paginationLink {
  color: #3987E3;
  margin: 0 5px;
  cursor: pointer;
}

.paginationNoLink {
  color: #3987E3;
  margin: 0 5px;
  cursor: default;
}

.selected{
  color: #4D5964;
}

.disabled {
  cursor: default;
  opacity: .3;
}
