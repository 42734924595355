.container {
  box-sizing: border-box;
  padding: 10px;
  min-width: 40px;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;

}

.dot {
  width: 4px;
  height: 4px;
  background: var(--color-mainDark);
  border-radius: 50%;
}
