.container {
  background: var(--color-white);
  padding: 44px 48px;
}

.headerWrapper{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  font-size: var(--header-h3);
  color: var(--color-mainDark);
  text-transform: uppercase;
  font-family: var(--Pragmatica);
  font-weight: 200;
  letter-spacing: 0.03em;
  line-height: 41px;
}
