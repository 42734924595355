.wrapper {
  position: relative;
  padding-top: 11px;
}

.select {
  border-bottom: 1px solid var(--color-border);
}

.select__header {
  padding-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.select__subheader {
  position: absolute;
  top: -10px;
  left: 0;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}

.select__options {
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid var(--color-border);
  border-top: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
  background-color: var(--color-white);
}

.select__headerName {
  padding: 0;
  height: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-mainDark);
  font-family: var(--LadaPragmatica);
  width: 100%;
}
.select__headerName:focus {
  outline: none;
}
.select__headerName_empty {
}
.select__headerName_filled {
}
.select__headerName::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica);
}

/**
  Scroll bar
 */
/* полоса прокрутки (scrollbar) */
.select__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: var(--color-white);
}

/* ползунок scrollbar`а */
.select__options::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}
/* Для FireFox */
.select__options {
  scrollbar-color: var(--color-lightGray) var(--color-white);
  scrollbar-width: thin;
}

.select__item {
  white-space: pre-wrap;
  cursor: pointer;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 8px;
}

.select__item:hover {
  background-color: var(--color-lightGray);
  color: var(--color-white);
}

.noItemPlug{
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 8px;
}

.resetElement {
  margin-top: 6px;
  margin-bottom: 5px;
}

.error {
  margin-top: 3px;
  color: var(--color-error-red);
  bottom: 6px;
  font-size: 12px;
  font-family: var(--LadaPragmatica);
}

.disableOption {
  opacity: .5;
}
.disableOption:hover {
  color: var(--color-lightGray);
  background-color: var(--color-white);
}
