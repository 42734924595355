.wrapper {
  position: relative;
  border: none;
  background: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.disabled {
  opacity: .3;
}

.titleMenu {
  display: flex;
  align-items: center;
}

.titleMenu > span {
  margin-right: 10px;
}

.contextMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.optionContainer {
  padding: 4px;
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 8px 12px;
}

.option:hover {
  background: #F4F6F7;
}

.contextMenu > span {
  width: 3px;
  height: 3px;
  background: var(--color-lightGray);
  display: block;
  border-radius: 50%;
}
