.container {
  max-width: 900px;
  font-family: LADAPragmatica, serif;
}
.selectList {
  display: flex;
  max-width: 900px;
  gap: 20px;
}
.checkboxList {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 32px 0;
  overflow-y: scroll;
  height: 252px;
}
.checkboxList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
}
.checkboxList::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}
.checkboxItem {
  display: flex;
  width: 50%;
  color: #66727d;
  margin-bottom: 12px;
  cursor: pointer;
}
.icons {
  display: flex;
  color: #66727d;
  height: 35px;
}

.iconsItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s;
}
.iconsItem img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.iconsItem:hover {
  opacity: 0.75;
}

.iconsItem:first-child {
  margin-right: 25px;
  align-items: center;
}

.loading {
  padding: 60px 80px;
  display: flex;
  justify-content: center;
  color: #66727d;
  font-size: 16px;
  font-family: 'LADAPragmatica', serif;
}
.footer__button_wrapper {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
