.waveWrap {
  display: flex;
  justify-content: space-between;
  align-items:flex-end;
}
.waveWrap div:first-child {
  flex-grow: 2;
}
.wrapper{
  width: 500px;
}
.customInput {
  border-bottom: 1px solid #E5E9ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 12px;
  margin: 8px 0 24px;
}

.icon {
  cursor: pointer;
  padding-right: 8px;
}

.dealersNameList {
  display: flex;
  max-height: 150px;
  font-family: var(--LadaPragmatica), serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  font-style: normal;
  color: var(--color-lightGray);
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dealersNameList li {
  max-width: 580px;
  color: var(--color-mainDark);
  margin-right: 8px;
}

.uncontrolledField {
  height: 56px;
  padding-top: 11px;
  border-bottom: var(--color-border) 1px solid;
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica), serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
}

.custonInputName {
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica), serif;
  font-weight: 400;
  font-size: 16px;
}

.dealersNameList::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}
