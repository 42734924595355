.container {
  width: 100%;
  max-width: 1032px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white);
}

.header {
  background-color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding-left: 24px;
  padding-right: 24px;
}

.title {
  color: var(--color-white);
  font-family: var(--Pragmatica);
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.body {
  padding: 24px;
}

.contentContainer {
  min-height: 370px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text {
  color: var(--color-dark-blue);
  font-family: var(--LadaPragmaticaB);
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.5;
  max-width: 690px;
  display: flex;
  align-items: center;
}

.titleError {
  color: var(--color-dark-blue);
  font-family: var(--LadaPragmaticaB);
  font-size: 18px;
  line-height: 1.3;
}

.textError {
  color: var(--color-dark-blue);
  font-family: var(--LadaPragmatica);
  font-size: 15px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.actions {
  display: flex;
  margin-top: 40px;
}

.actions > button:first-child {
  margin-right: 5px;
}

.actions > button:last-child {
  margin-left: 5px;
}
