.container {
  min-height: 370px;
}

.title {
  font-family: var(--LadaPragmaticaB);
  font-size: 18px;
  text-transform: uppercase;
  color: var(--color-dark-blue);
}

.titleQuestion {
  font-family: var(--LadaPragmatica);
  color: var(--color-dark-blue);
  min-height: 16px;
}

.titleQuestion b {
  font-family: var(--LadaPragmaticaB);
}

.content {
  margin-top: 13px;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 712px;
}

.comment {
  color: var(--color-dark);
  font-size: 13px;
  font-family: var(--LadaPragmatica);
  margin-top: 16px;
}
