.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-mainBackground);
}

.wrapper {
  min-height: calc(100vh - 133px);
  flex-grow: 2;
  padding: 28px 40px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContent{
  font-family: LADAPragmatica, serif;
  font-size: 13px;
  color: #4D5964;
}
