.wrapper {
  position: relative;
  padding-top: 11px;
}

.disabled {
  opacity: .7;
}

.zIndex20 {
  z-index: 20;
}

.select {
  border-bottom: 1px solid var(--color-border);
  padding: 18px 0 6px;
}

.selected{
  background: var(--color-orange);
  color: #FFFFFF !important;
}

.select__header {
  height: 21px;
  padding-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.select__subheader {
  position: absolute;
  top: -14px;
  left: 0;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}

.select__options {
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid var(--color-border);
  border-top: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
  background-color: var(--color-white);
}

.select__clear {
  text-decoration: underline;
  position: sticky;
  background: #fff;
  top: 0;
}

.select__options::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
}

.select__options::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}

.select__headerName {
  width: 100%;
  padding: 0;
  flex-grow: 1;
  height: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-mainDark);
  font-family: var(--LadaPragmatica);
}
.select__headerName:focus {
  outline: none;
}
.select__headerName_empty {
}
.select__headerName_filled {
}
.select__headerName::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica);
}

.select__item {
  white-space: pre-wrap;
  cursor: pointer;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 8px;
}

.select__item:hover {
  background-color: var(--color-lightGray);
  color: var(--color-white);
}

.noItemPlug{
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 8px;
}

.resetElement {
  margin-top: 6px;
  margin-bottom: 5px;
}

.error {
  margin-top: 3px;
  color: var(--color-error-red);
  bottom: 6px;
  font-size: 12px;
  font-family: var(--LadaPragmatica);
}

.disableOption {
  opacity: .5;
}
.disableOptionClear {
  color: rgba(178, 178, 178, 0.21);
}

.disableOption:hover {
  color: var(--color-lightGray);
  background-color: var(--color-white);
}
