.container {
  display: flex;
}

.container:not(:last-child) {
  margin-bottom: 10px;
}

.input {
  display: none;
}

.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-white);
  flex: 0 0 24px;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox svg {
  fill: var(--color-white) !important;
}

.checkbox:hover {
  border-color: var(--color-orangeShade);
}

.input:checked ~ .checkbox {
  background-color: var(--color-orangeShade);
  border-color: var(--color-orangeShade);
}

.label {
  margin-left: 8px;
  padding-top: 4px;
  line-height: 1.3;
  font-size: 15px;
  font-family: var(--LadaPragmatica);
  color: var(--color-dark-blue);
}
