.wrapper {
  height: 100%;
}
.list {
  display: flex;
  height: 100%;
  list-style: none;
  padding: 0 20px;
}
.list>li{
  margin-right: 32px;
}
.focused {
  margin-top: 1px;
  border-bottom: 2px solid var(--color-orange);
}
.navLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  font-family: LADAPragmaticaBold, serif;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-mainDark);
  box-sizing: border-box;
  font-size: 13px;
}
.list>li:last-child {
  margin-right: 0
}


@media screen and (max-width: 1440px){
  .list>li{
    margin-right: 16px;
  }
  .navLink{
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px){
  .list>li{
    margin-right: 8px;
  }
  .navLink{
    font-size: 12px;
  }
}
