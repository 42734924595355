.wrapper {
  flex-grow: 1;
  height: 520px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 24px;
  gap: 38px
}
.row {
  display: flex;
}
.control {
  border-top: 1px solid #E5E9ED;
  padding-top: 24px;
  margin-top: auto;
  gap: 16px;
}
.row > div:nth-child(n+1) {
  margin-right: 45px;
}
.infoCellWrapper{
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: LADAPragmatica, serif;
}
.infoCellWrapperFullWidth {
  width: 100%;
  flex-grow: 2;
}
.label {
  color: var(--color-gray)
}
.value {
  color: var(--color-mainDark)
}
