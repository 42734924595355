.form-type-select-use-form {

}

.checkbox-list {
  height: 272px;
  padding-top: 20px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-auto-rows: 21px;
  grid-row-gap: 12px;
  grid-column-gap: 49px;
}

.checkbox-list-unit {
  height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox-list-unit > div {
  cursor: pointer;
}
.checkbox-list-unit__label {
  margin-left: 8px;
  font-family: var(--LadaPragmatica);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--color-darkGray);
  cursor: pointer;
}

/**
    Scroll bar
 */
/* полоса прокрутки (scrollbar) */
.checkbox-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: var(--color-white);
}

/* ползунок scrollbar`а */
.checkbox-list::-webkit-scrollbar-thumb {
  background-color: var(--color-lightGray);
  border-radius: 4px;
}
/* Для FireFox */
.checkbox-list {
  scrollbar-color: var(--color-lightGray) var(--color-white);
  scrollbar-width: thin;
}

.control-panel {
  width: 273px;
  height: 21px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.control-panel__button {
  height: 100%;
  width: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--LadaPragmatica);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--color-darkGray);
  cursor: pointer;
}

.control-panel__button > div > div {
  height: 20px;
}