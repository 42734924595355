.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 24px;
  background: #fff;
  font-family: LADAPragmatica, serif;
  color: var(--color-gray);
}

.footer>p {
  text-transform: uppercase;
  font-size: 12px;
}
