.container {
  display: flex;
  flex-direction: column;
}


.titleContainer {
  position: relative;
  background: var(--color-mainDark);
  display: flex;
  padding: 20px 25px;
  gap: 12px;
  align-items: center;
  transition: opacity .2s ease;
}
.loading {
  opacity: .5;
}
.input {
  color: #fff !important;
}

.border {
  border-bottom: 1px solid #fff;
}

.btn {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
}
.dot {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.44);
  border-radius: 50%;
}
.contextMenu {
  position: absolute;
  top: 100%;
  right: 25px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
  font-family: LADAPragmatica, serif;
}
.variant {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--color-mainDark)
}

.variant:hover {
  background: var(--color-gray);
  color: #fff;
}

.variant:hover > svg {
  fill: #fff;
}
