.container {
  max-width: 700px;
  width: 100%;
}

.title {
  color: var(--color-lightGray);
  font-family: var(--LadaPragmaticaB);
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 10px;
}

.normalFont {
  font-family: var(--LadaPragmatica);
  text-transform: none;
}

.extraMargin {
  margin-top: 16px;
}

.dropzone {
  border: 2px dashed var(--color-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
  transition: all .3s;
}

.dropzoneActive {
  border-color: var(--color-lightGray);
}

.dropzoneIcon {
  margin-bottom: 10px;
}

.dropzoneText, .dropzoneLoading {
  max-width: 286px;
  font-size: 15px;
  text-align: center;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  line-height: 1.3;
}

.dropzoneLoading b {
  font-family: var(--LadaPragmaticaB);
  color: var(--color-gray);
}

.dropzoneText label {
  display: inline;
  cursor: pointer;
  color: var(--color-orangeShade);
}

.input {
  display: none;
}

.error {
  margin-top: 3px;
  color: var(--color-error-red);
  bottom: 6px;
  font-size: 12px;
  font-family: var(--LadaPragmatica);
}
