.container {
  padding-top: 20px;
  width: 100%;
  background: #fff;
  overflow: scroll;
}

.textAlignCenter {
  text-align: center;
}

.table {
  width: 100%;
  color: var(--color-mainDark)
}

.tableRow {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}

.tableRow > td {
  /*max-width: 250px;*/
}

.tableHeader {
  font-family: LADAPragmatica, serif;
  color: #848E98;
}

.background {
  background: #F4F4F4;
}

.tableCell {
  font-family: LADAPragmatica, serif;
  padding: 15px 27px;
}

.tableCellHeader {
  font-family: LADAPragmaticaBold, serif;
  font-weight: 700;
}

.tableCellBorder {
  border: 1px solid silver;
}

.noTopBorder {
  border-top: none;
}

.description {
  margin-bottom: 10px;
}

.text {
  margin-bottom: 10px;
}

.green {
  color: #3DB05D;
}

.red {
  color: var(--color-error-red)
}

.appealTextWrapper {
  width: 300px;
  text-align: left;
  padding: 8px;
  background: rgba(57, 135, 227, 0.1);
}

.appealAnswerTextWrapper {
  text-align: left;
}

.rejectedWrapper {
  background: #FFE4D7;
}

.acceptedWrapper {
  background: rgb(239,255,221);
}

.appealTextWrapper:not(:last-child) {
  margin-bottom: 10px;
}

.user {
  /*padding: 5px;*/
  /*background: #FFFFFF;*/
  border-radius: 5px;
  margin-bottom: 10px;
  /*box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px -2px inset;*/
}

.bold {
  font-family: LADAPragmaticaBold, serif;
}
