.table {
  width: 100%;
  font-family: var(--LadaPragmatica), serif;
}
.table .tableRow:last-child {
  border: none;
}
.tableRow {
  border-bottom: 1px solid var(--color-border);
}
.tableRow .tableCell:last-child {
  border: none;
}
.tableCell {
  color: var(--color-mainDark);
  padding: 15px 15px;
  border-right: 1px solid var(--color-border);
}
.tableHeadCell {
  background-color: var(--color-mainBackground);
  color: var(--color-gray);
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}
.tableMainCell {
  font-weight: 700;
}
.tableUnboarder {
  border: none;
}
.tableBordBot {
  border-bottom:  1px solid var(--color-border);
}
.tableFullBord {
  border:  1px solid var(--color-border);
}
.xs {
  width: 65px;
}
.m {
  width: 230px;
}
.xl {
  width: 430px;
}