.button {
  display: flex;
  align-items: center;
  background: none;
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #51b0fd;
  padding: 0;
  text-decoration: underline;
  border: none;
  border-radius: 30px;
  transition: opacity .2s ease;
}

.button:disabled {
  opacity: 0.3;
  cursor: default;
}

.button:active:not(:disabled) {
  opacity: .4;
}

.leftMargin{
  margin-left: 5px;
}
.rightMargin{
  margin-right: 5px;
}


