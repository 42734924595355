.dropdownBtn {
  background: none;
  border: none;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconWrapper {
  margin-bottom: -2px;
  margin-left: 8px;
}

.iconRotate {
  transform: rotate(180deg);
}

.list {
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px 8px;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}

.listItem {
  cursor: pointer;
  padding: 5px;
}

.listItem:hover {
  background: rgba(0, 0, 0, 0.04);
}

.dropdownInput {
  border: none;
  border-bottom: 1px solid var(--color-border);
  outline: none;
  max-width: 100%;
}
