.controlDefaultContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 5px;
}

.controlDefaultContainer > button:nth-child(1n) {
  margin-left: 12px;
}
.controlDefaultContainer > button:first-child {
  margin-left: 0;
}

.sections {
  width: inherit!important;
  height: 35px!important;
}

.sections > div:first-child > span {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--color-mainDark);
}

.sections > div:nth-child(2) {
  right: 0;
  width: 170px;
  margin-top: 6px;
}

.sections > div:nth-child(2) > div {
  text-align: left;
}

.activePath {
  color: var(--color-orangeShade) !important;
}
