.tableHeader {
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid #000;
}

.tableHeaderCell {
  display: flex;
  align-items: center;
}

.tableHeaderCell > p {
  margin-right: 5px;
  white-space: nowrap;
}

.isSort {
  cursor: pointer;
}

.isActive {
  color: var(--color-blueShade-links)
}
