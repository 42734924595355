.container {
  height: 100%;
  background: #FFFFFF;
  animation: moveIn .2s ease;
  overflow-y: auto;
}

@keyframes moveIn {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0);
  }
}
