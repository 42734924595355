.button {
  display: flex;
  align-items: center;
  background: var(--color-orange);
  width: fit-content;
  padding: 9px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: var(--color-white);
  border: none;
  border-radius: 30px;
  transition: opacity .2s ease;
}

.button:disabled {
  opacity: 0.3;
  cursor: default;
}

.button:active:not(:disabled) {
  opacity: .4;
}

.leftMargin{
  margin-left: 5px;
}
.rightMargin{
  margin-right: 5px;
}


