@font-face {
  font-family: "LADAPragmatica";
  src:   url("../fonts/LADAPragmatica.otf"),
  url("../fonts/LADAPragmatica.woff2") format("woff2"),
  url("../fonts/LADAPragmatica.woff") format("woff"),
  url("../fonts/LADAPragmatica.eot") format("eot");
}

@font-face {
  font-family: "LADAPragmaticaBold";
  src:   url("../fonts/LADAPragmatica-Bold.otf");
}

@font-face {
  font-family: "Pragmatica";
  src: url("../fonts/PragmaticaLightC.woff") format("woff");
}
