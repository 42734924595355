.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.46);
  display: none;
  z-index: 98;
}

.show {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showFilter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.modalView {
  z-index: 2;
  position: relative;
}

.filterView {
  position: relative;
  height: 100%;
}

.modalClose {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}
.modalCloseFilterAnimation {
  animation: filterAnimation .4s ease-in;
}

.modalCloseBtn {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

@keyframes filterAnimation {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
