.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 500px;
  width: 500px;
}

.listWrapper {
  overflow-y: auto;
}

.listItem {
  align-items: center;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  font-family: LADAPragmatica, serif;
}

.control {
  display: flex;
  align-items: center;
}

.test {
  padding: 0;
  border-bottom: 0;
}

.listItem:nth-child(1n) {
  border-top: 1px solid var(--color-border);
}

.listItem:last-child {
  border-bottom: 1px solid var(--color-border);
}
