.container {
  background: var(--color-white);
  padding: 20px 20px 24px;
}

.header {
  font-size: var(--header-h4);
  color: var(--color-mainDark);
  text-transform: uppercase;
  font-family: var(--Pragmatica);
  font-weight: 200;
  line-height: 36px;
  letter-spacing: 0.06em;
}
