.container {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-family: var(--LadaPragmatica);
}

.body {
  display: flex;
  padding: 1px;
  width: 28px;
  height: 16px;
  border: 2px solid var(--color-lightGray);
  border-radius: 10px;
  justify-content: flex-start;
}

.active {
  justify-content: flex-end;
  border: 2px solid var(--color-orange);

}

.coreActive {
  background: var(--color-orange) !important;
}

.core {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--color-lightGray);
}

.hiddenBox {
  display: none;
}
