.container {
  /*height: 388px;*/
  flex-basis: 422px;
  padding: 20px 24px;
}

.files {
  position: relative;
  padding-bottom: 65px;
  overflow: hidden;
}
