.container {
  margin-top: 24px;
}

.bar {
  height: 6px;
  background-color: var(--color-bar-background);
  position: relative;
  margin-bottom: 12px;
}

.filled {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  display: inline-block;
  background-color: var(--color-bar-filled);
  max-width: 100%;
  min-width: 0;
}

.value {
  color: var(--color-bar-filled);
  font-family: var(--LadaPragmaticaB);
  font-size: 13px;
  text-transform: uppercase;
}
