.button {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: var(--color-orange);
  border-radius: 30px;
  border: 2px solid var(--color-orange);
  box-sizing: border-box;
}

.leftMargin{
  margin-left: 5px;
}
.rightMargin{
  margin-right: 5px;
}

.disabled {
  opacity: .5;
  cursor: default;
}
