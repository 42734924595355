.main {
  display: flex;
}

.main > div:first-child {
  margin-right: 12px;
}

.main > div:last-child {
  margin-left: 12px;
}

.container {
  position: relative;
  padding-top: 10px;
  width: 71px;
  flex: 0 0 71px;
}

.focused {
  z-index: 9;
}

.label {
  color: var(--color-lightGray);
  font-family: var(--LadaPragmatica);
  font-size: 16px;
  pointer-events: none;
  position: absolute;
  top: 25px;
  left: 30px;
  transition: .3s;
}

.filled {
  transform: translate(-30px, -20px);
  font-size: 10px;
}

.arrow {
}

.input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-white);
}

.input input {
  border: none;
  height: 43px;
  color: var(--color-mainDark);
  font-size: 15px;
  outline: none;
  width: 59px;
  flex: 0 0 59px;
}

.options {
  margin-top: 10px;
  position: absolute;
  background-color: var(--color-white);
  max-height: 230px;
  overflow-y: auto;
  width: 170px;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  padding: 24px 24px 18px;
}

.optionItem {
  color: var(--color-mainDark);
  font-family: var(--LadaPragmatica);
  font-size: 14px;
  padding-bottom: 8px;
  cursor: pointer;
  display: block;
}

.optionItem:hover, .selected {
  font-family: var(--LadaPragmaticaB);
}
