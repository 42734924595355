.container {
  background: #fff;
  padding: 20px 24px;
  margin-bottom: 10px;
  border-left: 2px solid #fff;
}

.selected {
  border-left: 2px solid var(--color-orange);
}

.dragContainer {
  display: flex;
  justify-content: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
}
.attention {
  margin-top: 15px;
  font-family: LADAPragmatica, serif;
  color: #5c9348;
}
