.container {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  background: #FFFFFF;
  color: var(--color-mainDark);
  font-family: var(--LadaPragmatica);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 50;
}
