.container {
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 286px;
}

.controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resetElement {
  cursor: pointer;
}

.resetWrapper {
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resetHeader {
  font-family: var(--LadaPragmatica);
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #A6ADB5;
}
