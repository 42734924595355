.editorWrapper {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editArea {
  width: 100%;
  font-family: var(--LadaPragmatica);
  margin-top: 20px;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--color-border);
}

.delimiter {
  height: 26px;
  width: 1px;
  background: var(--color-mainDark);
}

.areaWrapper {
  position: relative;
}

.toolbar {
  display: flex;
  gap: 5px;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--LadaPragmatica);
  color: var(--color-lightGray);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}
