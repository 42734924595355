.statusMark {
  padding: 8px;
  background: rgba(57, 135, 227, 0.1);
  color: rgba(57, 135, 227, 1);
  text-transform: uppercase;
  font-family: LADAPragmaticaBold, serif;
  font-size: 11px;
  border-radius: 4px;
}
.cursorPointer {
  cursor: pointer;
}
.blocked {
  background: rgb(241, 70, 70, 0.1);
  color: rgb(241, 70, 70);
}

