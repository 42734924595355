.tableContainer thead  {
  background: #fff;
  z-index: 1;
}

.tableContainer thead tr > :first-child,
.tableContainer tbody tr > :first-child {
  background: #fff;
  position: sticky;
  left: 0;
}

.tableContainer thead tr > :first-child:after,
.tableContainer tbody tr > :first-child:after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  box-shadow: inset 2px 0 3px rgba(0, 0, 0, 0.05);
  right: -20px;
  width: 20px;
  transition: all .3s;
}

.tableContainer thead tr > :last-child,
.tableContainer tbody tr > :last-child {
  background: #fff;
  position: sticky;
  right: 0;
}

.tableContainer thead tr > :last-child:after,
.tableContainer tbody tr > :last-child:after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  box-shadow: inset -2px 0 3px rgba(0, 0, 0, 0.05);
  left: -20px;
  width: 20px;
  transition: all .3s;
}

.tableContainer tbody tr {
  background-color: var(--color-green-table);
}

.tableContainer tbody tr > :last-child {
  background-color: var(--color-green-table);
}

.isLeftScrolled thead tr > :first-child:after,
.isLeftScrolled tbody tr > :first-child:after {
  opacity: 0;
}

.isRightScrolled thead tr > :last-child:after,
.isRightScrolled tbody tr > :last-child:after {
  opacity: 0;
}
